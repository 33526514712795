.App {
  text-align: center;
}

.f-monteserrat {
  font-family: 'Montserrat', sans-serif;
}

.f-heading {
  font-family: 'Lora', serif;
}

.f-text {
  font-family: 'Roboto', sans-serif;
}

.btn-custom {
  background-color: #271033;
}

.mbg-primary {
  background-color: #271033;
}
